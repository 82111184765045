<template>
  <div>
    <h1>Enigmes</h1>
    <TheQuestion />
  </div>
</template>

<script>
import TheQuestion from "@/components/TheQuestion.vue";

export default {
  name: "HomeView",
  components: {
    TheQuestion,
  },
};
</script>
