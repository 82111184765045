<template>
  <div>
    <template v-if="step < questions.length">
      <p>{{ questions[step].text }}</p>
      <input v-model="inputValue" placeholder="Propose ta solution" />
      <button @click="submit">Valider</button>
    </template>
    <div v-else style="font-size: 24px">
      🎉🎉🎉
      <br />
      Felicitation, c'est gagné !
      <br />
      🎉🎉🎉

      <button @click="resetStep">Réinitialiser</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import JSConfetti from "js-confetti";

const wrongAudio = new Audio(require("../assets/audio/wrong.mp3"));
const correctAudio = new Audio(require("../assets/audio/correct.mp3"));
const successAudio = new Audio(require("../assets/audio/success.mp3"));

export default {
  name: "TheQuestion",
  computed: {
    ...mapState(["step"]),
    questions() {
      return [
        {
          text: "Solution de l'enigme n°1 (voir indice)",
          answer: "Daniel",
        },
        {
          text: "Solution de l'enigme n°2",
          answer: "2CV",
        },
        {
          text: "Solution de l'enigme n°3",
          answer: "Chenay-le-Châtel",
        },
        {
          text: "Solution de l'enigme n°4",
          answer: "Canasson",
        },
        {
          text: "Solution de l'enigme n°5",
          answer: "Dauphiné",
        },
      ];
    },
  },
  data() {
    return {
      inputValue: "",
    };
  },
  methods: {
    ...mapActions(["nextStep", "resetStep"]),
    submit() {
      if (
        this.inputValue
          .toLowerCase()
          .includes(this.questions[this.step].answer.toLowerCase())
      ) {
        if (this.step + 1 < this.questions.length) {
          this.playCorrectAnswerAudio();
        }
        this.nextStep();
        this.clearInput();
      } else {
        this.playWrongAnswerAudio();
      }
    },
    clearInput() {
      this.inputValue = "";
    },
    playCorrectAnswerAudio() {
      correctAudio.play();
    },
    playWrongAnswerAudio() {
      wrongAudio.play();
    },
    playSuccessAudio() {
      successAudio.play();
    },
  },
  watch: {
    step(value) {
      if (value === this.questions.length) {
        this.playSuccessAudio();
        const jsConfetti = new JSConfetti();
        jsConfetti.addConfetti({
          emojis: ["🌈", "⚡️", "💥", "✨", "💫", "🌸"],
          confettiRadius: 6,
          confettiNumber: 50,
        });

        setTimeout(() => {
          jsConfetti.addConfetti({
            emojis: ["🌈", "⚡️", "💥", "✨", "💫", "🌸"],
            confettiRadius: 6,
            confettiNumber: 50,
          });
        }, 1000);

        setTimeout(() => {
          jsConfetti.addConfetti({
            emojis: ["🌈", "⚡️", "💥", "✨", "💫", "🌸"],
            confettiRadius: 6,
            confettiNumber: 50,
          });
        }, 2000);

        setTimeout(() => {
          jsConfetti.addConfetti({
            emojis: ["🌈", "⚡️", "💥", "✨", "💫", "🌸"],
            confettiRadius: 6,
            confettiNumber: 50,
          });
        }, 3000);
      }
    },
  },
};
</script>

<style scoped>
input {
  margin-top: 16px;
  height: 36px;
  padding: 0px 8px;
}

button {
  margin-top: 16px;
  padding: 12px 16px;
  background-color: aquamarine;
  border-radius: 0;
  border: 3px solid blue;
  border-top: none;
  border-left: none;
  width: 100%;
}

button:active {
  border: none;
  background-color: aqua;
}
</style>
