<template>
  <div>
    <h1>Indices</h1>
    <TheHints />
  </div>
</template>

<script>
import TheHints from "@/components/TheHints.vue";

export default {
  name: "HintsView",
  components: {
    TheHints,
  },
};
</script>

<style></style>
