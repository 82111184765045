import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

/* eslint-disable no-new */
const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      key: "daniel-80",
    }),
  ],
  state: {
    step: 0,
  },
  mutations: {
    NEXT_STEP(state) {
      state.step = state.step + 1;
    },
    RESET_STEP(state) {
      state.step = 0;
    },
  },
  actions: {
    nextStep({ commit }) {
      commit("NEXT_STEP");
    },
    resetStep({ commit }) {
      commit("RESET_STEP");
    },
  },
});

export default store;
