<template>
  <nav>
    <div class="wrapper">
      <router-link to="/">
        <button :class="{ 'button--active': $route.name === 'home' }">
          Enigmes
        </button>
      </router-link>
      <router-link to="hints">
        <button :class="{ 'button--active': $route.name === 'hints' }">
          Indices
        </button>
      </router-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: "AppNavigationBar",
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
button {
  padding: 8px 16px;
  border: 1px solid grey;
  background-color: burlywood;
}
.button--active {
  font-weight: 500;
  background-color: lightyellow;
  border-width: 2px;
  border-color: black;
}
</style>
