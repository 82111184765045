<template>
  <div id="app">
    <AppNavigation />
    <div class="container">
      <router-view />
    </div>
    <AppNavigationBar />
  </div>
</template>

<script>
import AppNavigation from "@/components/AppNavigation.vue";
import AppNavigationBar from "@/components/AppNavigationBar.vue";

export default {
  name: "App",
  components: {
    AppNavigation,
    AppNavigationBar,
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
  --header-height: 64px;
  --nav-bar: 48px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  max-width: 360px;
  margin: auto;
  background-color: antiquewhite;
  position: relative;
  z-index: 0;
}

.container {
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  /* - 18 px is for the padding top and the borders widh for header and nav  */
  min-height: calc(100vh - var(--header-height) - var(--nav-bar) - 18px);
}

header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: var(--header-height);
  border-bottom: 1px solid grey;
  background-color: burlywood;
  z-index: 1;
}

nav {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  height: var(--nav-bar);
  border-top: 1px solid grey;
  background-color: wheat;
  display: flex;
  align-items: center;
  justify-content: center;
}

h1 {
  margin-bottom: 24px;
}
</style>
