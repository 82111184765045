<template>
  <header><span>80 ans Daniel</span></header>
</template>

<script>
export default {
  name: "AppNavigation",
};
</script>

<style scoped>
header {
  font-size: 24px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
