<template>
  <div id="the-hints">
    <template v-if="currentHint">
      <div
        v-for="hint in currentHint.hints"
        :key="hint.value"
        style="margin-bottom: 32px; font-size: 24px"
      >
        <div v-if="hint.type === 'string'">
          {{ hint.value }}
        </div>
        <img v-else :src="hint.value" />
      </div>
    </template>
    <div v-else>Les indices ne sont pas disponibles pour cette énigme.</div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TheHints",
  computed: {
    ...mapState(["step"]),
    hintsList() {
      return [
        {
          step: 0,
          hints: [
            {
              type: "image",
              value: require("../assets/images/question-0.png"),
            },
          ],
        },
        {
          step: 1,
          hints: [
            {
              type: "image",
              value: require("../assets/images/question-1.jpg"),
            },
          ],
        },
        {
          step: 2,
          hints: [
            {
              type: "image",
              value: require("../assets/images/question-2.png"),
            },
          ],
        },
        {
          step: 3,
          hints: [
            {
              type: "string",
              value: "43.5513500 7.0127500",
            },
            {
              type: "image",
              value: require("../assets/images/question-3.jpg"),
            },
          ],
        },
        {
          step: 4,
          hints: [
            {
              type: "image",
              value: require("../assets/images/question-4--1.jpg"),
            },
            {
              type: "image",
              value: require("../assets/images/question-4--2.jpg"),
            },
          ],
        },
      ];
    },
    currentHint() {
      return this.hintsList.find((hint) => hint.step === this.step);
    },
  },
};
</script>

<style scoped>
#the-hints img {
  width: 100%;
  object-fit: contain;
}
</style>
